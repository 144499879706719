import React from "react";
import { Navbar as BootstrapNavbar, Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

const NavBar = () => {
  return (
    <BootstrapNavbar bg="dark" variant="dark" expand="lg" className="px-5">
      <LinkContainer to={"/dashboard"}>
        <BootstrapNavbar.Brand>Grand CMS</BootstrapNavbar.Brand>
      </LinkContainer>
      <BootstrapNavbar.Toggle aria-controls="basic-navbar-nav" />
      <BootstrapNavbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          <LinkContainer to={"/dashboard/view"}>
            <Nav.Link>Pregled</Nav.Link>
          </LinkContainer>
        </Nav>
      </BootstrapNavbar.Collapse>
    </BootstrapNavbar>
  );
};

export default NavBar;
