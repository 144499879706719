import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import apiService from "../../services/ApiService";
import { useParams } from "react-router";
import { Form, Row, Col, Container, Button } from "react-bootstrap";
import moment from "moment";

const EditBill = () => {
  const { id } = useParams();
  const [bill, setBill] = useState();
  const [journal, setJournal] = useState("");

  const handleJournalChange = (event) => {
    setJournal(event.target.value);
  };

  const getBill = async () => {
    const [success, error] = await apiService.get(
      "cms/" + id
    );

    if (error) {
      toast.error("Greska pri ucitavanju racuna!");
      return;
    }

    setBill(success.data);
    setJournal(success.data.journal);
  };

  const save = async () => {
    if (journal == null || journal == "") {
      toast.error("Polje ne sme biti prazno!");
      return;
    }

    const [success, error] = await apiService.put(
      "cms/" + id, { journal }
    );

    if (error) {
      toast.error("Greska pri izmeni racuna!");
      return;
    }
  }

  const onSubmit = () => {
    save()
  }

  useEffect(() => {
    getBill();
  }, [id]);

  return (
    <Container className="px-5 py-5">
      {bill && (
        <Form onSubmit={onSubmit}>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>ID</Form.Label>
              <Form.Control readOnly value={bill._id} />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Korisnik</Form.Label>
              <Form.Control readOnly value={bill.user} />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>PFR broj</Form.Label>
              <Form.Control readOnly value={bill.pfr} />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Broj racuna</Form.Label>
              <Form.Control readOnly value={bill.receiptCounter} />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Ukupno</Form.Label>
              <Form.Control readOnly value={bill.totalAmount} />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>PFR vreme</Form.Label>
              <Form.Control readOnly value={bill.pfrDate} />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Tip</Form.Label>
              <Form.Control readOnly value={bill.type} />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Datum kreiranja</Form.Label>
              <Form.Control readOnly value={moment(bill.createdAt).format('LLLL')} />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Datum izmene</Form.Label>
              <Form.Control readOnly value={moment(bill.updatedAt).format('LLLL')} />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Journal</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                value={journal}
                onChange={handleJournalChange}
              />
            </Form.Group>
          </Row>
          <Button type="submit">Sacuvaj</Button>
        </Form>
      )}
    </Container>
  );
};

export default EditBill;
