import React, { useEffect, useState } from "react";
import { Pagination, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import apiService from "../../services/ApiService";
import moment from "moment";

const BillDashboard = () => {
  var itemsPerPage = 10;
  const navigate = useNavigate();
  const [bills, setBills] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentItems, setCurrentItems] = useState([]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const totalPages = Math.ceil(bills?.length / itemsPerPage);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  }

  const edit = (id) => {
    navigate("/dashboard/edit/" + id);
  };

  const getBills = async () => {
    const [success, error] = await apiService.get("cms");

    if (error) {
      toast.error("Greska pri ucitavanju racuna!");
      return;
    }

    setBills(success.data);
  };

  useEffect(() => {
    setCurrentItems(bills?.slice(indexOfFirstItem, indexOfLastItem));
  }, [bills, currentPage]);

  useEffect(() => {
    getBills();
  }, []);

  return (
    <div className="px-5 py-5">
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>ID</th>
            <th>Korisnik</th>
            <th>PFR broj</th>
            <th>Broj racuna</th>
            <th>Ukupno</th>
            <th>PFR Vreme</th>
            <th>Tip</th>
            <th>Datum kreiranja</th>
            <th>Datum izmene</th>
          </tr>
        </thead>
        <tbody>
          {currentItems?.map((item) => (
            <tr key={item._id}>
              <td className='text-overflow'>{item._id}</td>
              <td className='text-overflow'>{item.user}</td>
              <td className='text-overflow'>{item.pfr}</td>
              <td className='text-overflow'>{item.receiptCounter}</td>
              <td className='text-overflow'>{item.totalAmount}</td>
              <td className='text-overflow'>{item.pftDate}</td>
              <td className='text-overflow'>{item.type}</td>
              <td className='text-overflow'>{moment(item.createdAt).format('LLLL')}</td>
              <td className='text-overflow'>{moment(item.updatedAt).format('LLLL')}</td>

              <td>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => edit(item._id)}
                >
                  Izmeni
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Pagination>
        <Pagination.Prev
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        />
        {[...Array(totalPages).keys()]?.map((number) => (
          <Pagination.Item
            key={number + 1}
            active={number + 1 === currentPage}
            onClick={() => paginate(number + 1)}
          >
            {number + 1}
          </Pagination.Item>
        ))}
        <Pagination.Next
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === totalPages}
        />
      </Pagination>
    </div>
  );
};

export default BillDashboard;
