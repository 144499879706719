import moment from 'moment';
import './App.css';
import AppRoutes from './routes/AppRoutes';
import "./services/intercerptor"
import 'moment/locale/sr';
function App() {
  moment.locale('sr');
  return (
    <AppRoutes />
  );
}

export default App;
