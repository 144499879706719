import { Navigate, Outlet } from "react-router-dom";

const isAuthenticated = () => {
  var token = localStorage.getItem("token");
  if (token == null) {
    return false;
  } else {
    return true;
  }
};

export const PublicRoute = () => {

  if (isAuthenticated()) {
    return <Navigate to={"/dashboard/view"} />;
  }

  return (
      <Outlet className="example" />
  )
};