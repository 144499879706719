import React from "react";
import { Route, Navigate, Routes } from "react-router-dom";
import { ProtectedRoute } from "./ProtectedRoute";
import { PublicRoute } from "./PublicRoute";
import LoginForm from "../pages/Login/Login";
import BillDashboard from "../pages/dashboard/BillDashboard";
import EditBill from "../pages/dashboard/EditBill";

function AppRoutes() {
    return (
        <Routes>
            <Route path="/*" element={<PublicRoute />}>
                <Route path="login" element={<LoginForm />} />
            </Route>
            <Route path="/*" element={<ProtectedRoute />}>
                <Route path="dashboard">
                    <Route path="view" element={<BillDashboard />} />
                    <Route path="edit/:id" element={<EditBill/>} />
                </Route>

                <Route path="*" element={<Navigate to="/login" replace />} />
            </Route>
        </Routes>
    )
}

export default AppRoutes;